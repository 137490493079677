module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Simon Egli","short_name":"Simon Egli","start_url":"/","background_color":"#304CFD","theme_color":"#304CFD","display":"standalone","icon":"./static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"042677fb855e2e2a51de3421f3de4ca0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null,null,null,[null,{"target":"_blank","rel":"nofollow"}]],"rehypePlugins":[null,null,null],"gatsbyRemarkPlugins":["gatsby-remark-embed-gist","gatsby-remark-responsive-iframe",{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"files","ignoreFileExtensions":["md"]}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1000,"quality":80,"showCaptions":true,"linkImagesToOriginal":true}},{"resolve":"gatsby-remark-embed-snippet","options":{}},{"resolve":"gatsby-remark-prismjs","options":{"aliases":{"sh":"bash"}}}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/simologos.github.io/simologos.github.io/example"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXXXXX-X","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"black","showSpinner":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en","plugins":[null]}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"abstract","store":true},{"name":"content","store":true},{"name":"url","store":true}],"resolvers":{"Mdx":{}},"filename":"search_index.json","fetchOptions":{"credentials":"same-origin"}},
    },{
      plugin: require('../../gatsby-theme-academic/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content","googleAnalyticTrackingId":"UA-XXXXXXXXX-X","defaultLanguage":"en","pages":{"home":"/","posts":"posts","contact":"contact","resume":"resume","tags":"tags","research":"research"},"tagColors":["magenta","red","volcano","orange","gold","lime","green","cyan","blue","geekblue","purple"],"author":"Simon Egli","icon":"./static/favicon.png"},
    }]
