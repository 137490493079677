// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-academic-src-pages-404-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/404/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-404-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-contact-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/contact/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-contact-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-experience-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/experience/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-experience-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-posts-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/posts/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-posts-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-research-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/research/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-research-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-resume-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/resume.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-resume-jsx" */),
  "component---gatsby-theme-academic-src-pages-tags-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/tags/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-tags-index-jsx" */),
  "component---gatsby-theme-academic-src-pages-wakatime-index-jsx": () => import("./../../../../gatsby-theme-academic/src/pages/wakatime/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-pages-wakatime-index-jsx" */),
  "component---gatsby-theme-academic-src-templates-post-post-jsx": () => import("./../../../../gatsby-theme-academic/src/templates/post/post.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-templates-post-post-jsx" */),
  "component---gatsby-theme-academic-src-templates-tags-index-jsx": () => import("./../../../../gatsby-theme-academic/src/templates/tags/index.jsx" /* webpackChunkName: "component---gatsby-theme-academic-src-templates-tags-index-jsx" */)
}

