// extracted by mini-css-extract-plugin
export var badge = "sidebar-module--badge--wGah2";
export var badgeGray = "sidebar-module--badgeGray--8-v2Q";
export var boxContent = "sidebar-module--boxContent--k+wos";
export var boxName = "sidebar-module--boxName--xeZxA";
export var contactBlock = "sidebar-module--contactBlock--KcPbv";
export var contactBlockItem = "sidebar-module--contactBlockItem--whWe3";
export var content = "sidebar-module--content--NC1Ik";
export var iconButton = "sidebar-module--iconButton--JKoVD";
export var iconButtonCol = "sidebar-module--iconButtonCol--I2FuL";
export var infoListItem = "sidebar-module--infoListItem--fAIvU";
export var name = "sidebar-module--name--o3IP0";
export var profileAvatar = "sidebar-module--profileAvatar--jBeOx";
export var resumeDownload = "sidebar-module--resumeDownload--k4g2+";
export var rsBadgeBlue = "sidebar-module--rs-badge-blue--xMIcf";
export var rsBadgeContent = "sidebar-module--rs-badge-content--J0c15";
export var rsBadgeCyan = "sidebar-module--rs-badge-cyan--PPCmd";
export var rsBadgeGreen = "sidebar-module--rs-badge-green--RiLW6";
export var rsBadgeIndependent = "sidebar-module--rs-badge-independent--bpkkC";
export var rsBadgeOrange = "sidebar-module--rs-badge-orange--qaCsi";
export var rsBadgeRed = "sidebar-module--rs-badge-red--ryNQc";
export var rsBadgeViolet = "sidebar-module--rs-badge-violet--W2rGA";
export var rsBadgeYellow = "sidebar-module--rs-badge-yellow--EbHJl";
export var rsBtnBlue = "sidebar-module--rs-btn-blue--cWSml";
export var rsBtnCyan = "sidebar-module--rs-btn-cyan--ICjvE";
export var rsBtnGreen = "sidebar-module--rs-btn-green--MG2wO";
export var rsBtnOrange = "sidebar-module--rs-btn-orange--h5ft5";
export var rsBtnRed = "sidebar-module--rs-btn-red--LbC7I";
export var rsBtnViolet = "sidebar-module--rs-btn-violet--k+8O8";
export var rsBtnYellow = "sidebar-module--rs-btn-yellow--cRMAj";
export var rsFlexBoxGridItem1 = "sidebar-module--rs-flex-box-grid-item-1--Hz14H";
export var rsFlexBoxGridItem10 = "sidebar-module--rs-flex-box-grid-item-10--ARnts";
export var rsFlexBoxGridItem11 = "sidebar-module--rs-flex-box-grid-item-11--rMIwR";
export var rsFlexBoxGridItem12 = "sidebar-module--rs-flex-box-grid-item-12--mueZP";
export var rsFlexBoxGridItem13 = "sidebar-module--rs-flex-box-grid-item-13--SENhL";
export var rsFlexBoxGridItem14 = "sidebar-module--rs-flex-box-grid-item-14--lXi-G";
export var rsFlexBoxGridItem15 = "sidebar-module--rs-flex-box-grid-item-15--ximUf";
export var rsFlexBoxGridItem16 = "sidebar-module--rs-flex-box-grid-item-16--Wcehx";
export var rsFlexBoxGridItem17 = "sidebar-module--rs-flex-box-grid-item-17--9rcFP";
export var rsFlexBoxGridItem18 = "sidebar-module--rs-flex-box-grid-item-18--raGht";
export var rsFlexBoxGridItem19 = "sidebar-module--rs-flex-box-grid-item-19--6Fdlo";
export var rsFlexBoxGridItem2 = "sidebar-module--rs-flex-box-grid-item-2--6XV9S";
export var rsFlexBoxGridItem20 = "sidebar-module--rs-flex-box-grid-item-20--bgAOr";
export var rsFlexBoxGridItem21 = "sidebar-module--rs-flex-box-grid-item-21--fBPwa";
export var rsFlexBoxGridItem22 = "sidebar-module--rs-flex-box-grid-item-22--RuNUs";
export var rsFlexBoxGridItem23 = "sidebar-module--rs-flex-box-grid-item-23--HN4Xn";
export var rsFlexBoxGridItem24 = "sidebar-module--rs-flex-box-grid-item-24--ZbJXO";
export var rsFlexBoxGridItem3 = "sidebar-module--rs-flex-box-grid-item-3--b-nFU";
export var rsFlexBoxGridItem4 = "sidebar-module--rs-flex-box-grid-item-4--xLMjV";
export var rsFlexBoxGridItem5 = "sidebar-module--rs-flex-box-grid-item-5--NYbgx";
export var rsFlexBoxGridItem6 = "sidebar-module--rs-flex-box-grid-item-6--k0r7h";
export var rsFlexBoxGridItem7 = "sidebar-module--rs-flex-box-grid-item-7--6Zusu";
export var rsFlexBoxGridItem8 = "sidebar-module--rs-flex-box-grid-item-8--bE6Ft";
export var rsFlexBoxGridItem9 = "sidebar-module--rs-flex-box-grid-item-9--+gQGW";
export var rsFlexBoxGridItemOrder1 = "sidebar-module--rs-flex-box-grid-item-order-1--ckCKs";
export var rsFlexBoxGridItemOrder10 = "sidebar-module--rs-flex-box-grid-item-order-10--Y+dS4";
export var rsFlexBoxGridItemOrder11 = "sidebar-module--rs-flex-box-grid-item-order-11--xx5WU";
export var rsFlexBoxGridItemOrder12 = "sidebar-module--rs-flex-box-grid-item-order-12--ASCrF";
export var rsFlexBoxGridItemOrder13 = "sidebar-module--rs-flex-box-grid-item-order-13--fzfva";
export var rsFlexBoxGridItemOrder14 = "sidebar-module--rs-flex-box-grid-item-order-14--37CGo";
export var rsFlexBoxGridItemOrder15 = "sidebar-module--rs-flex-box-grid-item-order-15--0XDRy";
export var rsFlexBoxGridItemOrder16 = "sidebar-module--rs-flex-box-grid-item-order-16--OJFyM";
export var rsFlexBoxGridItemOrder17 = "sidebar-module--rs-flex-box-grid-item-order-17--rGjS5";
export var rsFlexBoxGridItemOrder18 = "sidebar-module--rs-flex-box-grid-item-order-18--1f3sI";
export var rsFlexBoxGridItemOrder19 = "sidebar-module--rs-flex-box-grid-item-order-19--gEg0w";
export var rsFlexBoxGridItemOrder2 = "sidebar-module--rs-flex-box-grid-item-order-2--OOTnX";
export var rsFlexBoxGridItemOrder20 = "sidebar-module--rs-flex-box-grid-item-order-20--muYyj";
export var rsFlexBoxGridItemOrder21 = "sidebar-module--rs-flex-box-grid-item-order-21--cFUty";
export var rsFlexBoxGridItemOrder22 = "sidebar-module--rs-flex-box-grid-item-order-22--n8BC3";
export var rsFlexBoxGridItemOrder23 = "sidebar-module--rs-flex-box-grid-item-order-23--YhbXp";
export var rsFlexBoxGridItemOrder24 = "sidebar-module--rs-flex-box-grid-item-order-24--nqGTi";
export var rsFlexBoxGridItemOrder3 = "sidebar-module--rs-flex-box-grid-item-order-3--7fFlJ";
export var rsFlexBoxGridItemOrder4 = "sidebar-module--rs-flex-box-grid-item-order-4--ouNfv";
export var rsFlexBoxGridItemOrder5 = "sidebar-module--rs-flex-box-grid-item-order-5--RCVWp";
export var rsFlexBoxGridItemOrder6 = "sidebar-module--rs-flex-box-grid-item-order-6--58zaO";
export var rsFlexBoxGridItemOrder7 = "sidebar-module--rs-flex-box-grid-item-order-7--PJxGJ";
export var rsFlexBoxGridItemOrder8 = "sidebar-module--rs-flex-box-grid-item-order-8--Tl8mC";
export var rsFlexBoxGridItemOrder9 = "sidebar-module--rs-flex-box-grid-item-order-9--zp4Fq";
export var rsTagBlue = "sidebar-module--rs-tag-blue--3iU07";
export var rsTagCyan = "sidebar-module--rs-tag-cyan--9hYrD";
export var rsTagGreen = "sidebar-module--rs-tag-green--kbKXH";
export var rsTagOrange = "sidebar-module--rs-tag-orange--4mUi8";
export var rsTagRed = "sidebar-module--rs-tag-red--DL8cY";
export var rsTagViolet = "sidebar-module--rs-tag-violet--6-99i";
export var rsTagYellow = "sidebar-module--rs-tag-yellow--fP9ll";
export var rsThemeDark = "sidebar-module--rs-theme-dark--Vv5k-";
export var rsThemeHighContrast = "sidebar-module--rs-theme-high-contrast--EANBY";
export var sideBar404Radius = "sidebar-module--sideBar404Radius--bW-gt";
export var sidebarContent = "sidebar-module--sidebarContent--ZD-7M";